@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#jitsi-iframe {
  width: 100%;
  height: 100vh;
  border: 0;
}
.leftwatermark {
  left: -100px !important;
  top: 32px;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
}

iframe {
  height: 100vh !important;
}

#largeVideoContainer a {
  display: none !important;
}

.logo {
  width: 160px;
  height: 70px;
  position: fixed;
  top: 16px;
  left: 10px;
  background: #000;
  background-image: url("./gdev.png");
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: left 30px center;
  z-index: 999;
  opacity: 0.9;
  border-radius: 10px;
}
